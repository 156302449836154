<template>
  <section>
    <!-- <pm-Breadcrumb :home="home" :model="items_bread" /> -->

    <div class="row">
      <div class="col-md-10">
        <h2 class="titulo-links-escolha" style="vertical-align:middle !important">
          <h2 class="titulo-links-escolha">Turma</h2>
        </h2>
      </div>
      <div class="col-md-2" >
        <pm-Button  type="button" @click="this.$router.push({name: 'coordenacao-turma-hab',  params: { pg: this.pg } });"
          icon="pi pi-angle-left"
          label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color" style="margin-top: 35px;float: right;">
        </pm-Button>
      </div>
    </div>

    <div class="pm-card" style="padding-left:20px;padding-right:20px;">
      <div class="row">
        <pm-TabView>
          <pm-TabPanel header="Disciplinas">
            <div class="col-md-12">
              <pm-DataTable :value="turmaDisciplinaAll"   dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters" responsiveLayout="scroll">
                <pm-Column field="disciplina" sortable header="Disciplina" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
                <pm-Column field="professor" sortable header="Professor" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
              </pm-DataTable>
            </div>
          </pm-TabPanel>
          <pm-TabPanel header="Alunos">
            <div class="col-md-12">
              <pm-DataTable :value="alunosALL"   dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters" responsiveLayout="scroll">
                <pm-Column field="nome" sortable header="Nome" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
              </pm-DataTable>
            </div>
          </pm-TabPanel>
        </pm-TabView>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Turma } from "@/class/turma.js";
import { Calendario } from "@/class/calendario.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Escola } from "@/class/escolas.js";
import { SeriesEscolar } from "@/class/serie.js";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { Servidores } from "@/class/servidores.js";

export default defineComponent({
  props: {
    id:{},
    pg:{}
  },
  data() {
    const columns = [
      { key: "nome", label: "Nome", sortable: true },
    ];
    const columns2 = [
      { key: "disciplina", label: "Disciplina", sortable: true },
      { key: "professor", label: "Professor", sortable: true },

    ];
    return {
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Visualizar Turma'},
      ],
      alunosALL: [],
      turmaAll: [],
      turmaDisciplinaAll: [],
      columns,
      columns2,
      perPage: 20,
      currentPage: 1,
      perPage2: 20,
      currentPage2: 1,

    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.alunosALL.length / this.perPage)
        : this.alunosALL.length;
    },
    pages2() {
      return this.perPage2 && this.perPage2 !== "0"
        ? Math.ceil(this.turmaDisciplinaAll.length / this.perPage2)
        : this.turmaDisciplinaAll.length;
    },
  },
  methods: {
    async turma() {
      const data = await Turma.turmaVerTudo (this.id);
      this.alunosALL = data.data["turma_alunos"];
      this.turmaAll = data.data;
      this.turmaDisciplinaAll = data.data["turma_disciplina"];

      const  tt = [
        {
          label: this.turmaAll["escola"]
        },
        {
          label: this.turmaAll["segmento"]
        },
        {
          label: this.turmaAll["serie"]
        },
        {
          label: this.turmaAll["nome"]
        },
        {
          label: this.turmaAll["turno"]
        },
      ];

      this.items_bread = tt;
    },
  },
  beforeMount(){
    this.turma();
  },
});
</script>

<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .va-table-responsive {
    overflow: auto;
  }
</style>
